.Main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  will-change: opacity;
  opacity: 1;
  transition: opacity 200ms;
  min-height: 720px;
}

.Main__transitioning {
  opacity: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.Main__transitioning::-webkit-scrollbar {
  display: none;
}

.Main__marquee {
  margin-bottom: 16px;
}

.Main__details {
  margin-top: -40px;
  margin-bottom: 48px;
  flex: 0 0 auto;
}

.Main__name {
  font-size: 36px;
  height: 40px;
  font-weight: 350;
  text-align: center;
}

/* .Main__description {
  text-align: center;
  padding: 0 15%;
  min-height: 40px;
  margin-top: 16px;
  line-height: 24px;
} */

.Main__price {
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
}

.Main__price_detail {
  font-weight: bold;
}

.Main__NFT {
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
}
