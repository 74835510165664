.Things {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  will-change: opacity;
  opacity: 1;
  transition: opacity 200ms;
}

.Things__transitioning {
  opacity: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.Things__transitioning::-webkit-scrollbar {
  display: none;
}

.Things__grid {
  height: 250px;
  grid-gap: 4px;
  display: grid;
  grid-template-columns: repeat(5, [col-start] 1fr);
  grid-template-rows: repeat(5, [row-start] 1fr);
}
