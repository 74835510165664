.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: white;
  transition: transform 0.2s ease;
}

.nav__hidden {
  transform: translateY(-100%);
}

.nav__content {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
}

.nav__list-item {
  list-style-type: none;
  white-space: nowrap;
  font-size: 16px;
  user-select: none;
}

.nav__list-item > * {
  display: inline-block;
  text-decoration: none;
  transform: scale(1);
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.nav__logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__title {
  margin-left: 8px;
}

.nav__list-item > *:visited,
.nav__list-item > *:link {
  color: inherit;
}

@media (hover: hover) {
  .nav__list-item > *:hover {
    transform: scale(1.1);
  }
}

.nav__list-item .active {
  font-weight: bold;
}

.nav__list-item:not(:last-child) {
  padding-right: 24px;
}

.nav__list {
  padding-inline-start: 0;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  display: flex;
  align-items: space-around;
  justify-content: center;
  flex-wrap: wrap;
}

button {
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

/* Remove excess padding and border in Firefox 4+ */
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button:hover {
  text-decoration: underline;
}
