.Modal__header {
  min-height: 20px;
  margin: -20px -20px 10px;
  padding: 10px 40px 0;
  font-weight: bold;
  position: relative;
}

.Modal__content {
  margin: 0 -20px -20px;
  padding: 20px;
}

.Modal__close_button {
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  color: rgba(114, 114, 114, 0.95);
}

.Modal__title {
  flex: 1 0 auto;
}

.ReactModal__Overlay--after-open {
  z-index: 11;
  background-color: rgba(114, 114, 114, 0.95) !important;
}
